import arrow1 from '../assets/modules/arrow1.svg'
import arrow2 from '../assets/modules/arrow2.svg'
import arrow3 from '../assets/modules/arrow3.svg'
import arrow4 from '../assets/modules/arrow4.svg'
import arrow5 from '../assets/modules/arrow5.svg'


import bg1 from '../assets/modules/module1.jpg'
import bg2 from '../assets/modules/module2.jpg'
import bg3 from '../assets/modules/module3.jpg'
import bg4 from '../assets/modules/module4.jpg'
import bg5 from '../assets/modules/module5.jpg'
import bg1_1 from '../assets/modules/module1-1.jpg'
import bg2_1 from '../assets/modules/module2-1.jpg'
import bg3_1 from '../assets/modules/module3-1.jpg'
import bg4_1 from '../assets/modules/module4-1.jpg'
import bg5_1 from '../assets/modules/module5-1.jpg'

class ModuleData {
	constructor(id, title, subt, topics, duration, descrTitle, descr, img, imgIn, arrow, textColor, videoName) {
		this.id = id;
		this.title = title;
		this.subt = subt;
		this.topics = topics;
		this.duration = duration;
		this.descrTitle = descrTitle;
		this.descr = descr;
		this.img = img;
		this.imgIn = imgIn;
		this.arrow = arrow;
		this.textColor = textColor;
		this.videoName = videoName;
	}
}
const DATA = [
	new ModuleData(1, 'Module 1 /', 'Clear Communication', '1 topic', '4 min', 'Module description', 'This module emphasises the importance of clear communication in the workplace to prevent safety violations and reduce casualties. It teaches workers about effective communication techniques, including active listening, speaking up when necessary, and promptly reporting incidents. By the end of this module, workers will have a better understanding of how to communicate with their colleagues, supervisors, and line managers to ensure a safer work environment for all.', bg4, bg4_1, arrow4, 'white', 'module1' ),
	new ModuleData(2, 'Module 2 /', 'Are you ready?', '6 topics', '9 min', 'Module description', "It's time to visit our first worksite! In module 2, we'll head to the London Underground to learn more about safe commuting and accessing the site. We'll start outside the premises, obtaining our sticker pass and signing in before heading underground to listen to the safety induction. During the induction, we'll learn about the necessary personal protective equipment (PPE), such as hard hats and safety boots, and the risks posed by third rails. We'll also learn about emergency arrangements, including evacuation procedures, and first aid procedures. This module will equip us with the knowledge and skills necessary to work safely in this environment and comply with HSE requirements.", bg1, bg1_1, arrow1, 'primary','module2'),
	new ModuleData(3, 'Module 3 /', 'On Site Hazards', '8 topics', '10 min', 'Module description', 'In this module we will continue to expore different TfL worksites to learn about common hazards and safety requirements. We will learn about the importance of identifying potential hazards, such as trip and fall hazards, and using appropriate safety equipment. This module provides workers with practical knowledge to help them stay safe on the job.', bg3, bg3_1, arrow3, 'primary', 'module3'),
	new ModuleData(4, 'Module 4 /', 'Mental Health and Wellbeing', '1 topic', '5 min', 'Module description', "What is mental health and how it impacts on your day to day life? Why is raising mental health awareness in the workplace important? Well, in this module we will focus on the importance of maintaining physical and mental wellbeing during work, and how to identify and address stress and fatigue. We'll also look at some tips and tools to support our well-being, which can lead to a safer work environment and better productivity.", bg5, bg5_1, arrow5, 'primary', 'module4'),
	new ModuleData(5, 'Module 5 /', 'Example Briefing', '1 topic', '4 min', 'Module description', "This add-on module takes us to office premises where safety training is given to employees before their first visit to the construction site. We'll learn the importance of following safety protocols, identifying hazards and wearing appropriate protective equipment. This module provides an example of how safety training can contribute to a safe work environment and prevent accidents.", bg2, bg2_1, arrow2, 'white', 'module5')
]

export default DATA